"use client"

import { getTrackerIdCookie } from "@/lib/cookies.client"
import { id } from "io-ts/Guard"
import { SiteConfig } from "@/config/site"


interface trackerArrayElementIf {
    id: number,
    created_at: Date,
}

type trackerArrayType = trackerArrayElementIf[];


export default function AddTrackerToLocalStorage() {
  //create local storage entry if it does not exist


  if (typeof window !== 'undefined') {
    const trackerArrayExists = window.localStorage.getItem(SiteConfig.localStorage.trackerArray);
    if (!trackerArrayExists) {
      window.localStorage.setItem(SiteConfig.localStorage.trackerArray, JSON.stringify([])); // set empty array if none exists
    }
    const trackerIdFromCookie = getTrackerIdCookie();
    if (!trackerIdFromCookie) {
      return <></>  // do not proceed if there is no tracker cookie
    }
    const previousArrayState = JSON.parse(window.localStorage.getItem(SiteConfig.localStorage.trackerArray) as string) as trackerArrayType;
    const lastArrayEntry: trackerArrayElementIf | undefined = previousArrayState.at(-1);
    const lastArrayEntryId: number | undefined = lastArrayEntry?.id;

    if(lastArrayEntryId !== trackerIdFromCookie) {

      // const searchParams = new URLSearchParams(window.location.search);
      // const tgiParam = searchParams.get("tgi");
      /*
      Reading from cookie because if we read from param any param can be set and stored in the array,
      Additionally, the middleware will read from the param and validate that param before setting it as a cookie. this way
      we ensure that what we store in the array is always a real tracker id, unless of course its manually edited.
      */

      const newTrackerArrayEntry: trackerArrayElementIf = {id: trackerIdFromCookie, created_at: new Date()};
      const newTrackerArray = [...previousArrayState, newTrackerArrayEntry];
      window.localStorage.setItem(SiteConfig.localStorage.trackerArray, JSON.stringify(newTrackerArray));
    }
  }

  return <></>
};